import { createApp } from 'vue';
import App from './App.vue';
import apiAxios from '../../config/request.js';
import '/src/assets/css/comment.css';
import '/src/assets/css/comment2.css';

// ant-design-vue 配置
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import baseFunc from '../../config/base_func.js';
// 创建实例
const app = createApp(App);

app.directive('focus', {
	// 当被绑定的元素挂载到 DOM 中时……
	mounted(el) {
		// 聚焦元素
		el.focus();
		// 选中内容
		el.select();
	},

})

app.config.globalProperties.$apiRequest = apiAxios; 
app.config.globalProperties.$baseFunc = baseFunc; 

app.use(Antd)
app.mount('#app')
