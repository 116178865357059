<template>
	<div class="tplContainer">
		<div class="width1200">
			<div v-if="showtype==1">
				<p class="tpltitle">视频转换为：链接/二维码/知识店铺</p>
				<div class="tpllists">
					<div class="tplinfo">
						<p class="tplinfo1">视频转换</p>
						<div class="tplinfo2">
							<span>1080P无损高清播放</span>
							<div class="cutline"></div>
							<span>一次转换终生有效</span>
							<div class="cutline"></div>
							<span>转换后支持更换视频</span>
						</div>
						<div class="tplinfo3">
							<div>无标识小程序打开模式</div>
							<div>h5网页打开模式</div>
							<div>提片小程序打开模式</div>
						</div>
						<!-- <button class="tplbutton" @click="tovideoComponents()">上传视频</button> -->
						<a-button class="tplbutton essential-color-main-btn" type="primary" size="large" shape="round" @click="tovideoComponents()">上传视频</a-button>
						<span class="mbm-iconfont mbm-shipin tplicon"></span>
					</div>
					<div class="tplinfo">
						<p class="tplinfo1">知识付费</p>
						<div class="tplinfo2">
							<span>一键上传视频极速审核</span>
							<div class="cutline"></div>
							<span>多种打开模式自由定价</span>
							<div class="cutline"></div>
							<span>1080P无损高清播放</span>
						</div>
						<div class="tplinfo3">
							<div>无标识小程序打开模式</div>
							<div>h5网页打开模式</div>
							<div>提片小程序打开模式</div>
						</div>
						<a-button class="tplbutton essential-color-main-btn" type="primary" size="large" shape="round" @click="skipKnowledge">立即制作</a-button>
						<span class="mbm-iconfont mbm-zhishi tplicon tp-icon-zhishi"></span>
					</div>
				</div>
				<div class="tplBottom">
					<div class="tplBottomL">
						<p class="tplBottomL1">企业专属服务</p>
						<p class="tplBottomL2">企业大规模应用，专属小程序定制服务 | 详情咨询：400-990-8722</p>
					</div>
					<div class="tplBottomR" @click="toContact">
						→
					</div>
				</div>
			</div>
			<videotpl v-if="showtype==2" :dataChange="dataChange" :generated="generated" :extra='extra' :videoData="videoData" @showMedialists="showMedialists"></videotpl>
			<audiotpl v-if="showtype==3" :dataChange="dataChange" :generated="generated" :extra='extra' :videoData="videoData" @showMedialists="showMedialists"></audiotpl>
			<a-modal  :destroyInClose="true"  cancelText="取消" okText="确认" :centered="true" :visible="visible" @ok="handleOk" @cancel="handleCancle" title="选择文件" width="92%" :destroyOnClose="true"
				wrapClassName="full-modal">
				<div style="width: 100%; height: calc(100vh - 248px);">
					<videoManage v-if="showtype==2" :isPageOrModel="isPageOrModel" @getlistsfromLib="getlistsfromLib"></videoManage>
					<audioManage v-if="showtype==3" :isPageOrModel="isPageOrModel" @getlistsfromLib="getlistsfromLib"></audioManage>
				</div>	
			</a-modal>
		</div>
	</div>
</template>

<script>
	import videotpl from "/src/components/video.vue";
	import audiotpl from "../../components/audio.vue";
	import videoManage from '../videoManage/App.vue';
	import audioManage from '../audioManage/App.vue';
	import config from "../../config/config.js";
	import {
		message
	} from 'ant-design-vue';
	export default {
		name: 'Home',
		components: {
			videotpl,
			audiotpl,
			videoManage,
			audioManage
		},
		data() {
			return {
				isPageOrModel: 2,
				visible: false,
				extra:{
					path:'',
					qrcode_url:''
				},
				showtype: 1, //1默认页面，2视频上传，3音频上传
				videoData: {
					change_type: 2, //转换形式:1二维码，2链接
					mode: 1, //打开方式:1小程序，2无标识小程序，3h5
					design: 1, //视频款式:1标准，2视频集，3视频刷一刷
					title: '',
					bjcolor: "#ffffff",
					/*背景色*/
					url_info: {
						url_type: 1, //域名:1默认，2vip，3自有
						url: ''
					},
					lists: [], //
				},
				libLists: [], //从附件库选择的附件
				generated:{
					generated:false,
					
				},
				dataChange:{
					dataChange:true,
					
				},
				id_keyuse:''
			}
		},
		methods: {
			toContact(){
				window.open(config.baseURL+'/help/3',"_blank");
			},
			getlistsfromLib(e) {
				this.libLists = e;
			},
			showMedialists(e) {
				this.visible = e;
			},
			handleOk(e) {
				var ids = this.libLists.join(',');
				this.getOneFileData(ids)
				this.visible = false;
			},
			handleCancle() {
				this.visible = false;
			},
			tovideoComponents: function() {
				this.showtype = 2
			},
			toaudioComponents: function() {
				this.showtype = 3
			},
			skipKnowledge: function() {
				if(config.devEnv=='dev'){
					window.open('/knowledgePayment.html');
				}else{
					window.open('https://dp.tipian.com');
				}
			},
			getMediaData(id_key) {
				this.generated.generated=true;
				this.dataChange.dataChange=false;
				this.$apiRequest.post('/qrcode/getQrcodeList', {
					id_key: id_key
				}).then(res => {
					// console.log(res)
					if (res.data.code == 200) {
						for(let i=0;i<res.data.data.content.lists.length;i++){
							res.data.data.content.lists[i].upload_hanlde={
								progress:101,
							}
						}
						this.videoData = res.data.data.content;
						this.extra=res.data.extra;
						if (res.data.type == 'video') {
							this.showtype = 2
						} else if (res.data.type = 'audio') {
							this.showtype = 3
						}
					}
				}).catch(err => {
					// message.error(err.message);
				})
			},
			getOneFileData(file_keys) {
				// this.generated.generated=false;
				this.$apiRequest.post('/qrcode/getBatchAttachInfor', {
					file_keys: file_keys
				}).then(res => {
					if (res.data.code == 200) {
						var arr = [];
						for (var i = 0; i < res.data.data.files.length; i++) {
							arr.push({
								id: res.data.data.files[i].id,
								sort: 0,
								title: res.data.data.files[i].filename,
								desc: "",
								cover: {
									id: 0,
									url: "",
								},
								filesize: res.data.data.files[i].filesize,
								url: res.data.data.files[i].url,
								width: res.data.data.files[i].width,
								height: res.data.data.files[i].height,
								duration: res.data.data.files[i].duration,
								extra: {
									title: "",
									desc: "",
									show_desc: 1,
									play_amount_set: 0,
									avatar: {
										id: 0,
										url: "",
									}
								},
								upload_hanlde:{
									progress:101,
								},
							})
						}

						this.videoData.lists = this.videoData.lists.concat(arr);
						if (res.data.data.type == 2) {
							this.showtype = 2
						} else if (res.data.data.type = 4) {
							this.showtype = 3
						}
					}

				}).catch(rres => {
					message.error(err.message);
				})
			}
		},
		watch:{
			videoData:{
				deep:true,
				handler(newval,oldval){
					if(this.id_keyuse!=''){
						this.dataChange.dataChange=false;
						this.id_keyuse='';
					}else{
						this.dataChange.dataChange=true;
					}
				}
			}
		},
		mounted() {
			var url = location.search;
			if (url.indexOf('?') != -1) { //判断是否有参数
				let arr = []; // 存储参数的数组
				let res = {}; // 存储最终JSON结果对象
				arr = url.split('?')[1].split('&'); // 获取浏览器地址栏中的参数
				for (let i = 0; i < arr.length; i++) { // 遍历参数
					if (arr[i].indexOf('=') != -1) { // 如果参数中有值
						let str = arr[i].split('=');
						res[str[0]] = str[1];
					} else { // 如果参数中无值
						res[arr[i]] = '';
					}
				}
				if (res.id_key !== undefined) {
					this.getMediaData(res.id_key)
					this.id_keyuse=res.id_key;//临时用
				} else if (res.file_keys !== undefined) {
					this.getOneFileData(res.file_keys)
					this.videoData.change_type=parseInt(res.change_type)
				}
			}
		}
	}
</script>

<style>
	
	.mbm-shipin {
		color: #d5eaf8;
	}

	.mbm-yinle {
		color: #fae1cf;
	}

	.tplinfo .tplicon {
		font-size: 115px;
		line-height: 115px;
		position: absolute;
		bottom: 26px;
		right: 62px;
	}
	.tplinfo .tp-icon-zhishi{
		color: #fae1cf;
		font-size: 100px;
		bottom: 20px;
	}

	.tplinfo .tplbutton {
		width: 160px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0px 0px 10px #95b4ff;
		margin-top: 55px;
		height: 50px !important;
	}
	.tplbutton span{line-height: 1;}

	.tplinfo3>div {
		display: inline-block;
		line-height: 38px;
		padding: 0 14px;
		border-radius: 20px;
		border: 1px solid #e7e7e7;
		margin-right: 13px;
		min-width: 125px;
		text-align: center;
	}

	.tplinfo3>div:last-child {
		margin-right: 0px !important;

	}

	.tplinfo3 {
		font-size: 12px;
		color: #999;
		margin-top: 20px;
	}

	.cutline {
		width: 1px;
		height: 16px;
		background-color: #e7e7e7;
		margin: 0 10px;
	}

	.tplinfo2 {
		display: flex;
		align-items: center;
		color: #999;
		font-size: 14px;
		line-height: 16px;
		margin-top: 20px;
	}

	.tplinfo1 {
		font-size: 30px;
		line-height: 30px;
		margin-top: 48px;
	}

	.tplBottomR {
		width: 40px;
		height: 40px;
		border-radius: 8px;
		background-color: #e7e7e7;
		display: flex;
		align-items: center;
		cursor: pointer;
		justify-content: center;
	}

	.tplBottomL1 {
		font-size: 30px;
		color: #333;
		display: inline-block;
	}

	.tplBottomL2 {
		color: #999;
		display: inline-block;
		font-size: 14px;
		margin-left: 20px;
	}

	.tplBottomL {}

	.tplBottom {
		box-sizing: border-box;
		width: 100%;
		height: 123px;
		border-radius: 18px;
		border: 1px solid #e2e1de;
		margin-top: 51px;
		padding: 0 100px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.width1200 {
		width: 1200px;
		margin: 0 auto;
	}

	.tplinfo {
		width: 580px;
		height: 324px;
		border-radius: 15px;
		background-color: #ffffff;
		box-shadow: 0px 10px 20px #f2f2f2;
		padding-left: 78px;
		position: relative;
	}

	.tpllists {
		margin-top: 84px;
		display: flex;
		justify-content: space-between;
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	.tplContainer {
		background-image: linear-gradient(#ecf3fc, #ffffff);
	}

	.tpltitle {
		font-size: 46px;
		line-height: 46px;
		text-align: center;
		padding-top: 75px;
	}
</style>
